<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading_builds"></v-skeleton-loader>
        <v-card elevation="0" v-else>
            <v-card-title style="margin-top:-8px;margin-left:-28px;margin-bottom:20px;">
                <v-toolbar style="position:fixed;z-index:10;width:100%;text-align:center;" elevation="0">
                    <v-btn @click="refreshBuilds(true)" small rounded color="primary" class="mx-2">
                        <v-icon class="mr-2">mdi-refresh</v-icon>
                        Refresh
                    </v-btn>
                    <v-btn text small color="primary" @click="show_pricing = !show_pricing">
                        <v-icon class="mr-2" v-if="show_pricing">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_pricing">Show Pricing</label>
                        <label style="cursor:pointer;" v-else>Hide Pricing</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_outline = !show_outline">
                        <v-icon class="mr-2" v-if="show_outline">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_outline">Show Summary</label>
                        <label style="cursor:pointer;" v-else>Hide Summary</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_itinerary = !show_itinerary">
                        <v-icon class="mr-2" v-if="show_itinerary">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_itinerary">Show Details</label>
                        <label style="cursor:pointer;" v-else>Hide Details</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_supplier_name = !show_supplier_name">
                        <v-icon class="mr-2" v-if="show_supplier_name">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_supplier_name">Show Supplier Name</label>
                        <label style="cursor:pointer;" v-else>Hide Supplier Name</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_product_name = !show_product_name">
                        <v-icon class="mr-2" v-if="show_product_name">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_product_name">Show Product Name</label>
                        <label style="cursor:pointer;" v-else>Hide Product Name</label>
                    </v-btn>
                    <v-btn text small color="primary" @click="show_option_name = !show_option_name">
                        <v-icon class="mr-2" v-if="show_option_name">mdi-eye-outline</v-icon>
                        <v-icon class="mr-2" v-else>mdi-eye-closed</v-icon>
                        <label style="cursor:pointer;" v-if="!show_option_name">Show Option Name</label>
                        <label style="cursor:pointer;" v-else>Hide Option Name</label>
                    </v-btn>
                    <v-checkbox v-model="group_accommodation_dates" label="Group By Accommodation Dates"
                        style="color:#1976D2;margin-top:20px;" />
                </v-toolbar>
            </v-card-title>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" rounded class="mx-2" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2">mdi-download</v-icon>
                            Print Itinerary
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="getBuildsWithBase64('landscape')">
                            <v-list-item-title>Landscape (default)</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="getBuildsWithBase64('portrait')">
                            <v-list-item-title>Portrait</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!-- <v-btn @click="getBuildsWithBase64()" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-download</v-icon>
                    Print Itinerary
                </v-btn> -->
                <v-btn @click="downloadContactList()" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-download</v-icon>
                    Contact List
                </v-btn>
                <v-btn @click="openPreviewLink()" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-link</v-icon>
                    Shareable Link
                </v-btn>
            </v-card-title>
            <v-card-text>
                <div id="itinerary-preview">
                    <v-card elevation="0" class="mb-15">
                        <v-img eager class="mx-auto my-5" style="object-fit:contain;width:25%;text-align:center;"
                            :src="get_selected_company.base64_logo" />
                        <div>
                            <h1 style="font-weight: 700; font-size:20px;text-align:center;color:#1DA057;">
                                {{ itinerary.itinerary_name }}
                            </h1>
                            <v-spacer />
                            <h5 style="font-weight: 500; font-size: 14px;text-align:center;">
                                {{ $date(itinerary.start_date).format('D MMM') }}
                                -
                                {{ $date(itinerary.end_date).format('D MMM YYYY') }}
                            </h5>
                        </div>
                        <v-row style="padding: 50px 60px 0px 60px;" v-if="show_pricing">
                            <v-col cols="7">
                                <h3 class="mb-5">TOTAL PRICE FOR THIS PACKAGE:</h3>
                                <h3 class="mb-5">PRICE PER PERSON:</h3>
                                <h3 class="mb-5">DEPOSIT:</h3>
                                <h3>BALANCE DUE:</h3>
                            </v-col>
                            <v-col cols="5">
                                <h3 class="mb-5">
                                    {{ itinerary.currency }} {{ getGross() | formatNumber }}
                                </h3>
                                <h3 class="mb-5">
                                    {{ itinerary.currency }}
                                    {{ getGross() / itinerary.travellers_number | formatNumber }}
                                </h3>
                                <h3 class="mb-5">
                                    {{ itinerary.currency }}
                                    {{ getGross() * itinerary.deposit_percent | formatNumber }}
                                </h3>
                                <h3>
                                    {{ itinerary.currency }}
                                    {{ getGross() - (getGross() * itinerary.deposit_percent) | formatNumber }}
                                </h3>
                            </v-col>
                        </v-row>
                    </v-card>
                    <br>
                    <div v-html="itinerary.comments" class="my-10 px-10"></div>
                    <br class="html2pdf__page-break" v-if="itinerary.comments">
                    <v-card elevation="0" class="mb-15" v-if="show_outline">
                        <div
                            style="text-align:center;font-size:26px;margin-top:20px;margin-bottom:20px;font-weight:600;color:#800000;">
                            ITINERARY SUMMARY
                        </div>
                        <hr>
                        <Outline :builds="builds_with_base64.length > 0 ? builds_with_base64 : grouped_builds"
                            :itinerary="itinerary" :show_supplier_name="show_supplier_name"
                            :show_product_name="show_product_name" :show_option_name="show_option_name"
                            :group_accommodation_dates="group_accommodation_dates" :printing="printing"
                            @hideBuild="hideBuild" />
                    </v-card>
                    <br class="html2pdf__page-break">
                    <v-card elevation="0" class="mb-15" v-if="show_itinerary">
                        <div
                            style="text-align:center;font-size:26px;margin-top:20px;margin-bottom:20px;font-weight:600;color:#800000;">
                            ITINERARY DETAILS
                        </div>
                        <hr>
                        <Itinerary :builds="builds_with_base64.length > 0 ? builds_with_base64 : grouped_builds"
                            :itinerary="itinerary" :show_supplier_name="show_supplier_name"
                            :show_product_name="show_product_name" :show_option_name="show_option_name"
                            :group_accommodation_dates="group_accommodation_dates" />
                    </v-card>
                </div>
                <div id="contact-list">
                    <v-card elevation="0">
                        <v-card-text style="text-align:center;">
                            <h1>Contact List</h1>
                            <br>
                            <h2>{{ itinerary.itinerary_name }}</h2>
                            <br>
                            <h3>Itinerary Ref: {{ itinerary.internal_reference }},
                                From: {{ $date(itinerary.start_date).format('D MMM, YYYY') }},
                                To: {{ $date(itinerary.end_date).format('D MMM, YYYY') }},
                                {{
                                    $date(itinerary.end_date).diff($date(itinerary.start_date),
                                        'DAY') + 1
                                }} days
                            </h3>
                            <br>
                            <div v-for="(type, contact_index) in contact_types" :key="contact_index">
                                <h3 style="text-align:left;">{{ type }}</h3>
                                <v-data-table :headers="headers" :items="builds.filter((obj, index) => {
                                    return index === builds.findIndex(o => obj.option.product.supplier.id === o.option.product.supplier.id)
                                        && obj.option.product.supplier.service_type_id == contact_index + 1
                                })" hide-default-footer disable-pagination>
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td><b>{{ $date(item.check_in_date).format('DD MMM YY') }}</b></td>
                                            <td><b>{{ $date(item.check_out_date).format('DD MMM YY') }}</b></td>
                                            <td style="text-align:left;"><b>{{
                                                item.option.product.supplier.supplier_name }}</b></td>
                                            <td style="text-align:left;"><b>{{ item.option.product.supplier.address
                                                    }}</b></td>
                                            <td style="text-align:left;"><b>{{ item.option.product.supplier.phone
                                                    }}</b>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                                <br>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Outline from './components/Outline.vue'
import Itinerary from './components/itinerary.vue'
import html2pdf from 'html2pdf.js'
export default {
    components: {
        Outline,
        Itinerary,
    },
    props: [
        'itinerary', 'builds', 'grouped_builds', 'conversion', 'loading_builds'
    ],
    data() {
        return {
            builds_with_base64: [],
            printing: false,
            show_pricing: true,
            show_outline: true,
            show_itinerary: true,
            show_supplier_name: true,
            show_product_name: false,
            show_option_name: false,
            group_accommodation_dates: true,
            items: [
                'Outline'
            ],
            tab: null,
            headers: [
                { text: 'Date in', width: '13%' },
                { text: 'Date out', width: '13%' },
                { text: 'Supplier' },
                { text: 'Address' },
                { text: 'Phone' },
            ],
            contact_types: ['Accommodation', 'Activity', 'Rental Car'],
            builds_to_hide: [],
        }
    },
    async mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
        }),
    },
    methods: {
        async refreshBuilds(message = false) {
            this.$emit('refreshBuilds')
            if (message) {
                this.$toast.info('Refreshing...')
            }
            this.builds_to_hide = []
        },
        hideBuild(to_hide) {
            this.builds_to_hide.push(to_hide)
            this.grouped_builds[to_hide.dayindex].splice(to_hide.buildindex, 1)
        },
        async openPreviewLink() {
            if (this.itinerary.encrypted_id == null) {
                await this.$axios.post(`v2/itineraries/update_itinerary_encrypted_id/${this.itinerary.id}`)
                    .then(({ data }) => {
                        if (data.response) {
                            this.$router.push({ name: 'View Itinerary', params: { id: data.data.encrypted_id } })
                        }
                    })
            }
            else {
                this.$router.push({ name: 'View Itinerary', params: { id: this.itinerary.encrypted_id } })
            }
        },
        getGross() {
            let total = 0
            this.builds.forEach(build => {
                let days = this.$date(build.check_out_date).diff(this.$date(build.check_in_date), 'd')
                if (this.itinerary.currency != build.option.product.supplier.currency) {
                    total += (build.rate_day ? build.rate_day.gross : 0) * (days > 0 ? days : 1) * build.count * this.conversion
                }
                else {
                    total += (build.rate_day ? build.rate_day.gross : 0) * (days > 0 ? days : 1) * build.count
                }
            })
            return Math.abs(total) + parseFloat(this.itinerary.service_charge) + parseFloat(this.itinerary.orr)
        },
        async getBuildsWithBase64(orientation) {
            this.printing = true
            this.$toast.info('Please wait...', { timeout: 10000 })
            await this.$axios.get(`v2/itineraries/get_builds_with_base64/${this.itinerary.id}`)
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.builds_with_base64 = Object.values(Object.groupBy(data.data, ({ ib_id }) => ib_id))
                        let sort_order = [1, 3, 2]
                        this.builds_with_base64.forEach(day => {
                            day.sort((a, b) => (sort_order.indexOf(a.option.product.supplier.service_type_id) > sort_order.indexOf(b.option.product.supplier.service_type_id))
                                ? 1 : ((sort_order.indexOf(b.option.product.supplier.service_type_id) > sort_order.indexOf(a.option.product.supplier.service_type_id)) ? -1 : 0))
                        })
                        this.builds_to_hide.forEach(to_hide => {
                            this.builds_with_base64[to_hide.dayindex].splice(to_hide.buildindex, 1)
                        })
                        if (orientation == 'landscape') {
                            setTimeout(() => {
                                this.printItineraryLandscape()
                            }, 3000)
                        }
                        else if (orientation == 'portrait') {
                            setTimeout(() => {
                                this.printItineraryPortrait()
                            }, 3000)
                        }
                    }
                })
        },
        printItineraryLandscape() {
            this.$toast.info('Printing...', { timeout: 10000 })
            const opt = {
                margin: 0.2,
                filename: this.itinerary.itinerary_name + '.pdf',
                image: { type: 'jpeg' },
                html2canvas: { scale: 1.2, useCORS: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
            }
            html2pdf().set(opt).from(document.getElementById('itinerary-preview')).save()
        },
        printItineraryPortrait() {
            this.$toast.info('Printing...', { timeout: 10000 })
            const opt = {
                margin: 0.2,
                filename: this.itinerary.itinerary_name + '.pdf',
                image: { type: 'jpeg' },
                html2canvas: { scale: 1.2, useCORS: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            }
            html2pdf().set(opt).from(document.getElementById('itinerary-preview')).save()
        },
        downloadContactList() {
            const opt = {
                margin: 0.1,
                filename: this.itinerary.itinerary_name + '-Contact List.pdf',
                image: { type: 'png', quality: 1 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            }
            html2pdf().set(opt).from(document.getElementById('contact-list')).save()
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(tr),
:deep(h3),
:deep(h2),
:deep(h1) {
    page-break-inside: avoid;
    color: black !important;
}

:deep(th) {
    color: black !important;
    font-weight: 800 !important;
}

:deep(button) {
    text-transform: unset !important;
}

:deep(.v-input__slot label),
:deep(.v-input--selection-controls__input i) {
    color: #1976D2;
    font-size: 14px;
}

:deep(.v-toolbar),
:deep(.v-toolbar__content) {
    height: 40px !important;
}

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500&display=swap');

#itinerary-preview h1, #itinerary-preview div, #itinerary-preview b {
    font-family:'Lexend', sans-serif!important;
}
</style>