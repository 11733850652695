<template>
    <v-container fluid>
        <div v-for="(day, dayindex) in builds" :key="dayindex">
            <v-row v-for="(build, buildindex) in day" :key="buildindex">
                <v-col cols="12" style="display:block;font-size:small;" class="pa-2"
                    :set="start_day = $date(build.check_in_date).diff($date(itinerary.start_date), 'd') + 1"
                    :set2="end_day = $date(build.check_out_date).diff($date(itinerary.start_date), 'd') + 1" :set3="is_between_days = (group_accommodation_dates == false) ? false :
                        (build.option.product.supplier.service_type_id == 2
                            && ((dayindex > 0 && builds[dayindex - 1].length > 0 && $date(build.check_in_date).isBefore($date(builds[dayindex - 1][0].check_out_date)))
                                || dayindex > 1 && builds[dayindex - 2].length > 0 && $date(build.check_in_date).isBefore($date(builds[dayindex - 2][0].check_out_date))
                                || dayindex > 2 && builds[dayindex - 3].length > 0 && $date(build.check_in_date).isBefore($date(builds[dayindex - 3][0].check_out_date)))
                        )">
                    <v-row v-if="buildindex == 0 && !is_between_days">
                        <v-col cols="1" v-if="!isMobile()"></v-col>
                        <v-col :cols="isMobile() ? 6 : 2">
                            <b style="color:#000F9D;font-size:16px;font-weight:800;">
                                <label v-if="dayindex == 0">Arrive - </label>
                                <label v-if="dayindex == builds.length - 1">Depart - </label>
                                Day {{ start_day }}
                                <label v-if="start_day != end_day">
                                    to {{ end_day }}
                                </label>
                            </b>
                        </v-col>
                        <v-col :cols="isMobile() ? 6 : 4">
                            <b>
                                {{ $date(build.check_in_date).format('MMMM D') }}
                                <label v-if="start_day != end_day">
                                    - {{ $date(build.check_out_date).format('MMMM D') }}
                                </label>
                            </b>
                        </v-col>
                        <v-col cols="5" v-if="!isMobile()"></v-col>
                    </v-row>
                    <v-row v-if="buildindex == 0 && !is_between_days" style="margin-bottom:-15px;margin-top:-15px;">
                        <v-col cols="1" v-if="!isMobile()"></v-col>
                        <v-col :cols="isMobile() ? 6 : 2" class="pl-15">
                            <b>Destination:</b>
                        </v-col>
                        <v-col :cols="isMobile() ? 6 : 4">
                            {{ build.option.product.supplier.town }}<label
                                v-if="build.option.product.supplier.town != build.option.product.supplier.county">,
                                {{ build.option.product.supplier.county }}
                            </label>
                        </v-col>
                        <v-col cols="5" v-if="!isMobile()"></v-col>
                    </v-row>
                    <v-row style="margin-bottom:-15px;margin-top:-15px;">
                        <v-col cols="1" v-if="!isMobile()"></v-col>
                        <v-col :cols="isMobile() ? 6 : 2" class="pl-15">
                            <b
                                v-if="build.option.product.supplier_product_type_id != 5 && (buildindex == 0 || build.option.product.supplier.service_type_id != day[buildindex - 1].option.product.supplier.service_type_id)">
                                {{
                                    build.option.product.supplier.service_type_id == 1 ? 'Hotel' :
                                        build.option.product.supplier.service_type_id == 2 ? 'Activities' :
                                            build.option.product.supplier.service_type_id == 3 ? 'Transportation' :
                                                'Other'
                                }}:
                            </b>
                        </v-col>
                        <v-col :cols="isMobile() ? 6 : 4">
                            <label v-if="show_supplier_name">{{ build.option.product.supplier.supplier_name }}</label>
                            <label
                                v-if="show_supplier_name && show_product_name || show_supplier_name && show_option_name">
                                - </label>
                            <label v-if="show_product_name">{{ build.option.product.name }}</label>
                            <label v-if="show_product_name && show_option_name">
                                - </label>
                            <label v-if="show_option_name">x{{ build.count }} {{ build.option.name }}</label>
                        </v-col>
                        <v-col cols="5" v-if="!isMobile()">
                            <v-btn v-if="!printing" @click="hideBuild(dayindex, buildindex)" icon small color="primary">
                                <v-icon>mdi-eye-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
export default {
    props: [
        'builds', 'itinerary', 'show_supplier_name', 'show_product_name', 'show_option_name', 'group_accommodation_dates', 'printing'
    ],
    methods: {
        hideBuild(dayindex, buildindex) {
            let payload = {
                dayindex: dayindex,
                buildindex: buildindex,
            }
            this.$emit('hideBuild', payload)
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
}
</script>

<style scoped lang='scss'>
:deep(label),
:deep(b) {
    page-break-inside: avoid;
}
</style>