<template>
    <div>
        <div class="header">
            <h3>{{ get_selected_company.company_name }}</h3>
        </div>
        <div class="card">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <div class="card-header">Itineraries</div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="card-header-items">
                        <v-btn rounded color="#525a68" dark class="header-items" @click="createNewItinerary()">
                            Create itinerary
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field label="Name" prepend-icon="mdi-magnify" v-model="itinerary_name"
                        @keyup.enter="searchItineraries()"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field label="Number" prepend-icon="mdi-magnify" v-model="itinerary_number"
                        @keyup.enter="searchItineraries()"></v-text-field>
                </v-col>
                <!-- <v-col>
                    <v-text-field label="Supplier Ref" prepend-icon="mdi-magnify" v-model="supplier_reference"
                        @keyup.enter="searchItineraries()"></v-text-field>
                </v-col> -->
                <v-col>
                    <v-autocomplete v-model="selected_assignee" label="Filter by assignee"
                        :items="[{ name: 'None', value: null }, ...all_users]" item-text="name" item-value="id"
                        @change="searchItineraries()"></v-autocomplete>
                </v-col>
                <v-col>
                    <v-select multiple v-model="selected_status" label="Filter by status"
                        :items="[{ name: 'None', value: null }, ...get_itinerary_status]" item-text="name"
                        item-value="id" @change="searchItineraries()"></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" style="display:flex;align-items:center;justify-content:end;">
                    Start Date
                </v-col>
                <v-col cols="2" class="px-3">
                    <v-menu v-model="start_from_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="start_from_date" label="From" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="start_from_date" @input="searchItineraries()"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2" class="px-3">
                    <v-menu v-model="start_to_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="start_to_date" label="To" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="start_to_date" @input="searchItineraries()"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="1" style="display:flex;align-items:center;justify-content:end;">
                    End Date
                </v-col>
                <v-col cols="2" class="px-3">
                    <v-menu v-model="end_from_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="end_from_date" label="From" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="end_from_date" @input="searchItineraries()"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2" class="px-3">
                    <v-menu v-model="end_to_menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="end_to_date" label="To" readonly v-bind="attrs"
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="end_to_date" @input="searchItineraries()"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="2">
                    <div style="display:flex;align-items:center;justify-content:center;">
                        <v-btn dark @click="searchItineraries()">Search</v-btn>
                        <v-btn text @click="resetSearch()" class="ml-5">Reset</v-btn>
                    </div>
                </v-col>
                <v-col cols="12" class="pt-5">
                    <v-divider></v-divider>
                    <v-skeleton-loader type="table" v-if="!loaded"></v-skeleton-loader>
                    <v-data-table v-if="loaded" :headers="headers" :items="itineraries" item-key="id"
                        :sort-by.sync="sort_by" :sort-desc.sync="sort_desc">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-chip class="data-chip" v-bind="attrs" v-on="on">
                                                {{ item.created_info.name.charAt(0).toUpperCase() }}
                                            </v-chip>
                                        </template>
                                        <span>{{ item.created_info.name }}</span>
                                    </v-tooltip>
                                </td>
                                <td>
                                    <a :href="app_url + 'itinerary/update/' + item.id"
                                        style="font-weight:700;font-size:16px;color:#389A74;">
                                        {{ item.itinerary_name }}
                                    </a>
                                </td>
                                <td>{{ item.itinerary_number }}</td>
                                <td>
                                    {{ $date(item.start_date).format('MMM DD, YYYY') }}
                                </td>
                                <td>
                                    {{ $date(item.end_date).format('MMM DD YYYY') }}
                                </td>
                                <td>
                                    <div v-for="(contributor, contributorindex) in item.contributor_info"
                                        :key="contributorindex">
                                        {{ contributor.user.name }}
                                    </div>
                                </td>
                                <td>
                                    {{ item.tour_type ? item.tour_type.name : '' }}
                                </td>
                                <td>
                                    {{ item.currency }}
                                </td>
                                <td>
                                    {{ $date(item.created_at).format('MMM DD, YYYY') }}
                                </td>
                                <td>
                                    <div class="text-center">
                                        <v-checkbox v-model="item.is_confirm"
                                            @click.stop="UpdateItinerary('is_confirm', item.is_confirm, item.id)">
                                        </v-checkbox>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <v-menu rounded offset-y>
                                            <template v-slot:activator="{ attrs, on }">
                                                <v-btn v-bind="attrs" v-on="on" small rounded dark color="primary">
                                                    {{ item.status_info.name }}
                                                    <v-icon>mdi-menu-down</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item v-for="status in get_itinerary_status" :key="status.id"
                                                    link>
                                                    <v-list-item-title v-text="status.name"
                                                        @click="UpdateItinerary('itinerary_status_id', status.id, item.id, 'status')">
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </td>
                                <td>
                                    <v-menu right bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="duplicateItinerary(item)">
                                                <v-list-item-title>Duplicate</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Itineraries',
    components: {
    },
    props: [],
    data: () => ({
        itineraries: [],
        original_itineraries: [],
        itineraries_file: null,
        show_archived: false,
        menu: false,
        loaded: false,
        headers: [
            { text: '', value: 'initial' },
            { text: 'Name', value: 'itinerary_name', width: '150px' },
            { text: 'Itinerary Number', value: 'itinerary_number', width: '150px' },
            { text: 'Start Date', value: 'start_date', width: '120px' },
            { text: 'End Date', value: 'end_date', width: '120px' },
            { text: 'Assignees', sortable: false },
            { text: 'Tour Type', value: 'tour_type_id' },
            { text: 'Currency', value: 'currency', width: '20px' },
            { text: 'Created', value: 'created_at', width: '150px' },
            { text: 'Confirmed', value: 'is_confirm', width: '20px' },
            { text: 'Status', sortable: false, width: '250px' },
            { text: '', value: 'action' },
        ],
        confirm_duration: 0,
        sort_by: 'created_at',
        sort_desc: true,
        itinerary_name: null,
        itinerary_number: null,
        supplier_reference: null,
        all_users: [],
        selected_assignee: null,
        selected_status: null,
        start_from_menu: false,
        start_to_menu: false,
        start_from_date: null,
        start_to_date: null,
        end_from_menu: false,
        end_to_menu: false,
        end_from_date: null,
        end_to_date: null,
        app_url: null,
    }),
    async mounted() {
        await this.$store.dispatch('itinerary/fetch_itinerary_status')
        await this.$store.dispatch('itinerary/fetch_itinerary_template')
        this.getItineraries()
        this.getAllUsers()
        this.app_url = process.env.VUE_APP_URL
    },
    created() {
        this.confirm_duration = process.env.VUE_APP_CONFIRM_DURATION
    },
    computed: {
        ...mapGetters({
            get_selected_company: 'auth/get_selected_company',
            get_itinerary_status: 'itinerary/get_itinerary_status',
            get_itinerary_template: 'itinerary/get_itinerary_template',
            get_user: 'auth/get_user',
        }),
    },
    methods: {
        async getItineraries() {
            let payload = {
                company_id: this.get_selected_company.id,
            }
            await this.$axios.get('v2/itineraries/get_latest_itineraries', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.itineraries = data.data
                        this.loaded = true
                    }
                })
        },
        async duplicateItinerary(item) {
            await this.$axios.post('itinerary/duplicate', item)
                .then(() => {
                    this.getItineraries()
                })
        },
        createNewItinerary() {
            this.$router.push({ name: 'CreateItinerary' })
        },
        async getAllUsers() {
            await this.$axios.get('/itinerary/fetch_users_all')
                .then(({ data }) => {
                    this.all_users = data.data
                })
        },
        resetSearch() {
            this.loaded = false
            this.itinerary_name = null
            this.itinerary_number = null
            this.supplier_reference = null
            this.selected_assignee = null
            this.selected_status = null
            this.start_from_date = null
            this.start_to_date = null
            this.end_from_date = null
            this.end_to_date = null
            this.getItineraries()
        },
        async searchItineraries() {
            this.loaded = false
            this.start_from_menu = false
            this.start_to_menu = false
            this.end_from_menu = false
            this.end_to_menu = false
            let payload = {
                itinerary_name: (this.itinerary_name != null && this.itinerary_name != '') ? this.itinerary_name : null,
                itinerary_number: (this.itinerary_number != null && this.itinerary_number != '') ? this.itinerary_number : null,
                supplier_reference: (this.supplier_reference != null && this.supplier_reference != '') ? this.supplier_reference : null,
                assignee: (this.selected_assignee != null && this.selected_assignee != 'None') ? this.selected_assignee : null,
                itinerary_status_id: (this.selected_status != null && this.selected_status != 'None') ? this.selected_status : null,
                start_from_date: (this.start_from_date != null && this.start_from_date != 'None') ? this.start_from_date : null,
                start_to_date: (this.start_to_date != null && this.start_to_date != 'None') ? this.start_to_date : null,
                end_from_date: (this.end_from_date != null && this.end_from_date != 'None') ? this.end_from_date : null,
                end_to_date: (this.end_to_date != null && this.end_to_date != 'None') ? this.end_to_date : null,
            }
            if (payload.itinerary_name == null && payload.itinerary_number == null && payload.supplier_reference == null && payload.assignee == null && payload.itinerary_status_id == null && payload.start_from_date == null && payload.start_to_date == null && payload.end_from_date == null && payload.end_to_date == null) {
                this.getItineraries()
            } else {
                this.$axios.get(`/itinerary/get_itineraries_by_filter/${this.get_selected_company.id}`, payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.itineraries = data.data
                            this.loaded = true
                        }
                    })
            }
        },
        UpdateItinerary(field, value, id, action = null) {
            let info = {
                id: id,
                field: field,
                value: value,
            }
            this.$axios.post('itinerary/update_itinerary', info)
                .then(({ data }) => {
                    if (data.response) {
                        this.$toast.success('Updated itinerary successfully')
                        if (action == 'status') {
                            let itinerary = this.itineraries.find(
                                (element) => element.id == id
                            )
                            let status = this.get_itinerary_status.find(
                                (element) => element.id == value
                            )
                            itinerary.itinerary_status_id = value
                            itinerary.status_info = status
                        }
                        if (action == 'template') {
                            let itinerary = this.itineraries.find(
                                (element) => element.id == id
                            )
                            let template = this.get_itinerary_template.find(
                                (element) => element.id == value
                            )
                            itinerary.itinerary_template_id = value
                            itinerary.template_info = template
                        }
                    }
                })
        },
    },
};
</script>

<style scoped>
.header {
    max-width: 100%;
    background-color: #79a2bd;
    height: 50px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
    text-align: center;
    position: relative;
    z-index: 1;
    line-height: 50px;
}

.header h3 {
    color: white;
}

.card {
    max-width: 100%;
    background-color: white;
    margin: 25px 15px;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.card .card-header {
    font-size: 24px;
    color: #343642;
    margin-bottom: 15px;
}

.card .card-header-items {
    text-align: right;
}

.card .card-header-items .header-items {
    margin: 0px 10px;
}

.filtertabs-wrapper {
    max-width: 100%;
    margin-top: 18px;
}

.data-chip {
    background-color: rgb(56, 154, 116) !important;
    font-weight: bolder;
    font-size: 14px;
    color: white;
}

.text-center {
    text-align: center;
}

@media only screen and (max-width: 959px) {
    .card .card-header {
        text-align: center;
    }

    .card .card-header-items .header-items {
        margin: 5px 5px 10px 5px;
    }
}
</style>
