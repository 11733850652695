<template>
    <v-container fluid> <!-- FOR REFACTOR -->
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-card elevation="0" v-else>
            <v-card-title>
                <v-btn @click="getBooking(true)" rounded color="primary" class="mx-2">
                    <v-icon class="mr-2">mdi-refresh</v-icon>
                    Refresh
                </v-btn>
                <v-spacer />
                <v-select v-model="group_bookings"
                    :items="[{ text: 'True', value: true }, { text: 'False', value: false }]" @change="getBooking()"
                    item-text="text" item-value="value" label="Group Bookings by Supplier" outlined dense hide-details
                    class="mr-2" style="max-width:200px;"></v-select>
                <v-btn class="pl-5 pr-5 ml-2 mr-2" rounded color="primary" style="text-transform: unset !important;"
                    @click="download_to_pdf()" :disabled="get_selected">
                    Create {{ selected.length }} voucher(s)
                </v-btn>
                <v-menu :disabled="get_selected">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :color="get_selected ? 'red' : 'primary'" dark v-bind="attrs" v-on="on" class="ml-2"
                            rounded style="text-transform: unset !important;">
                            Send {{ selected.length }} booking <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="pr-10">
                        <v-list-item v-for="(dd, dditem) in dd_booking" :key="dditem" style="cursor: pointer;"
                            @click="request_event(dd.action)">
                            <v-list-item-title>
                                <small style="font-weight: 600;">
                                    <v-icon class="mr-1">
                                        mdi-send-circle-outline
                                    </v-icon>
                                    {{ dd.text }}
                                </small>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-data-table :headers="booking_table_header" :items="bookings" class="mt-5" hide-default-footer
                    disable-pagination show-select item-key="id" v-model="selected" :sort-by.sync="sort_by"
                    :sort-desc.sync="sort_desc">
                    <template v-slot:item.days="props">
                        <div style="background: #343642; color: white !important; width: 90px;"
                            class="text-center pt-1 pb-1 pl-1 pr-1">
                            {{ get_range(props.item.check_in_date, props.item.check_out_date) === 0 ? 1 :
                                get_range(props.item.check_in_date, props.item.check_out_date) }} day(s)
                        </div>
                    </template>
                    <template v-slot:item.item_value="props">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <label style="font-weight:300;">
                                        {{ props.item.supplier_info.supplier_name }}
                                    </label>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="props.item.supplier_product">
                                    <small>{{ props.item.supplier_product.name }}</small>
                                    <br>
                                    <label class="custom-label mr-1">
                                        {{ props.item.supplier_product_option.name }}
                                    </label>
                                    <label class="custom-label mr-1" v-for="(child, index) in props.item.children"
                                        :key="index">
                                        {{ child.supplier_product_option.name }}
                                    </label>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    <label style="color:red;">Error: Please check this service in the build tab</label>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-slot:item.county="props">
                        {{ props.item.supplier_info.county }}
                    </template>
                    <template v-slot:item.supplier_type_id="props">
                        <div>
                            <v-icon color="black" v-if="props.item.supplier_product_type">
                                {{ props.item.supplier_product_type.icon }}
                            </v-icon>
                            <v-icon color="red" v-else>
                                mdi-alert-octagon
                            </v-icon>
                        </div>
                    </template>
                    <template v-slot:item.check_in_date="props">
                        <small>
                            {{ $date(props.item.check_in_date).format('MMM DD, YYYY') }}
                        </small>
                        -
                        <small style="display: block;">
                            {{ $date(props.item.check_out_date).format('MMM DD, YYYY') }}
                        </small>
                    </template>
                    <template v-slot:item.time="props">
                        <small>
                            {{ props.item.check_in_time }}
                        </small>
                        <small style="display: block;">
                            {{ props.item.check_out_time }}
                        </small>
                    </template>
                    <template v-slot:item.status_id="props">
                        <v-menu rounded offset-y>
                            <template v-slot:activator="{ attrs, on }">
                                <v-btn v-bind="attrs" v-on="on" small rounded
                                    :color="evtGetBookingStatusColor(props.item.get_booking_status)" dark>
                                    {{ props.item.get_booking_status != null
                                        ? props.item.get_booking_status.status
                                        : 'Not Yet Requested' }}
                                    <v-icon>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(status) in getBookingStatus" :key="status.id" link
                                    @click="evtChangeBookingStatus(props.item.id, status)">
                                    <v-list-item-title v-text="status.status"></v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:item.booking_reference_number="props">
                        <label>
                            {{ props.item.booking_reference_number }}
                            <v-icon small style="cursor: pointer;" @click="update_booking_id(props.item)">
                                mdi-pen
                            </v-icon>
                        </label>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <div style="max-width:900px;">
            <div id="element-to-convert-booking">
                <v-card elevation="0">
                    <v-card-text v-for="(item, itemindex) in selected" :key="itemindex" elevation="0"
                        style="color:black;">
                        <v-row style="border:solid black;border-width: 1px 1px 0px 1px;">
                            <v-col cols="6">
                                <v-row style="padding:5px 10px;">
                                    <v-col cols="3" style="padding:0px;">
                                        <b>Voucher for:</b>
                                    </v-col>
                                    <v-col cols="9" style="padding:0px;">
                                        <b>{{ item.supplier_info.supplier_name }}</b>
                                    </v-col>
                                    <v-col cols="3" style="padding:0px;"></v-col>
                                    <v-col cols="9" style="padding:0px;">
                                        {{ item.supplier_info.address }}
                                    </v-col>
                                    <v-col cols="3" style="padding:0px;"></v-col>
                                    <v-col cols="9" style="padding:0px;">
                                        {{ item.supplier_info.phone }}
                                    </v-col>
                                    <v-col cols="3" style="padding:0px;"></v-col>
                                    <v-col cols="9" style="padding:0px;">
                                        {{ item.supplier_info.website }}
                                    </v-col>
                                    <v-col cols="3" style="padding:0px;margin-top:10px;">
                                        <b>Client name:</b>
                                    </v-col>
                                    <v-col cols="9" style="padding:0px;margin-top:10px;">
                                        <b style="background-color:#D3E7FF;"
                                            v-if="itinerary.traveller_info[0] && itinerary.traveller_info[0].travellers_info">
                                            {{ JSON.parse(itinerary.traveller_info[0].travellers_info).firstname
                                                + ' '
                                                + JSON.parse(itinerary.traveller_info[0].travellers_info).lastname }}
                                        </b>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" style="padding:5px 10px;">
                                <v-img eager style="object-fit:contain;text-align:left;"
                                    :src="get_selected_company.base64_logo" />
                            </v-col>
                        </v-row>
                        <v-row style="border:solid black;border-width: 0px 1px 0px 1px;">
                            <v-col cols="5">
                                VOUCHER INCLUDES
                            </v-col>
                            <v-col cols="2">
                                START TIME
                            </v-col>
                            <v-col cols="2">
                                END TIME
                            </v-col>
                            <v-col>
                                NTS/DYS
                            </v-col>
                            <v-col>
                                QTY
                            </v-col>
                            <v-col>
                                REF
                            </v-col>
                        </v-row>
                        <v-row style="border:solid black;border-width: 1px 1px 1px 1px;">
                            <v-col cols="5">
                                {{ item.supplier_product.name }}
                            </v-col>
                            <v-col cols="2">
                                {{ $date(item.check_in_date).format('DD MMM YY') }}<br>{{ item.check_in_time ?
                                    $date(item.check_in_date + item.check_in_time).format('hh:mm A') : null }}
                            </v-col>
                            <v-col cols="2">
                                {{ $date(item.check_out_date).format('DD MMM YY') }}<br>{{ item.check_out_time ?
                                    $date(item.check_out_date + item.check_out_time).format('hh:mm A') : null }}
                            </v-col>
                            <v-col>
                                {{ $date(item.check_out_date).diff(item.check_in_date, 'day') }}
                            </v-col>
                            <v-col>
                                {{ item.count }}
                            </v-col>
                            <v-col>
                                {{ item.booking_reference_number }}
                            </v-col>
                        </v-row>
                        <v-row style="border:solid black;border-width: 0px 1px 0px 1px;">
                            <v-col cols="12" style="height:100px;">
                                NOTES:
                                <br>
                                <label v-if="item.supplier_info.auto_add_notes">
                                    {{ item.supplier_info.notes }}
                                </label>
                                <label v-else>
                                    {{ item.instructions }}
                                </label>
                            </v-col>
                        </v-row>
                        <v-row
                            style="border-bottom:solid 1px black;border-left:solid 1px black;border-right:solid 1px black;">
                            <v-col cols="3">
                                <b>Voucher no:</b> {{ new Date().getMilliseconds() }}
                            </v-col>
                            <v-col cols="3">
                                <b>Itinerary no:</b> {{ itinerary.itinerary_number }}
                            </v-col>
                            <v-col cols="3">
                                <b>Issued by:</b> {{ (itinerary.contributor_info[0]) ?
                                    itinerary.contributor_info[0].user.name : null }}
                            </v-col>
                            <v-col cols="3">
                                <b>Issue date:</b> {{ $date(Date.now()).format('DD MMMM, YYYY') }}
                            </v-col>
                        </v-row>
                        <br class="html2pdf__page-break" v-if="itemindex < selected.length - 1">
                    </v-card-text>
                </v-card>
            </div>
        </div>
        <v-dialog v-model="bk_id_model" persistent max-width="770px" overlay-color="#f4f4f5" overlay-opacity="1">
            <v-btn fab @click="bk_id_model = false" class="dialog-close">
                <v-icon dark> mdi-arrow-left </v-icon>
            </v-btn>
            <v-card>
                <v-card-title style="font-weight:lighter; font-size: 16px">
                    <label>Add supplier reference ID</label>
                </v-card-title>
                <v-divider />
                <v-card-text class="mt-5">
                    <v-text-field v-model="i.booking_id" label="Supplier Reference ID" dense outlined>
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn outlined class="pl-3 pr-3" rounded @click="closeBookingDialog()">
                        <v-icon class="mr-2">
                            mdi-close
                        </v-icon>
                        cancel
                    </v-btn>
                    <v-btn color="primary" class="pl-8 pr-8" rounded @click="save_bk_dialog()">
                        <v-icon class="mr-2">
                            mdi-content-save
                        </v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AvailabilityRequest :data="selected" :dialog_value="availability_request_model" :itinerary="itinerary"
            :data_length="selected.length" :title="title" @close_dialog="close_dialog" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import html2pdf from "html2pdf.js"
import AvailabilityRequest from './components/AvailabilityRequest.vue'
import Booking from './modules/Booking.js'
export default {
    components: {
        AvailabilityRequest
    },
    props: [
        'itinerary'
    ],
    data() {
        return {
            loading: true,
            bookings: [],
            timer: null,
            booking_table_header: [
                { text: 'ID', value: 'id' },
                { text: 'Days', value: 'days', sortable: false },
                { text: 'Item', value: 'item_value', width: '230', sortable: false },
                { text: 'County', value: 'county', sortable: false },
                { text: 'Type', value: 'supplier_type_id' },
                { text: 'Dates', value: 'check_in_date' },
                { text: 'Time', value: 'time', sortable: false },
                { text: 'Status', value: 'status_id' },
                { text: 'Supplier Reference', value: 'booking_reference_number' }
            ],
            selected: [],
            dd_booking: [
                { text: 'Booking request', action: 'booking_request' },
                { text: 'Cancellation request', action: 'cancellation_request' },
            ],
            availability_request_model: false,
            bk_id: null,
            bk_id_model: false,
            i: {
                booking_id: null
            },
            to_add_bk_id: {},
            title: null,
            booking_store: {},
            sort_by: 'check_in_date',
            sort_desc: false,
            group_bookings: false,
        }
    },
    async mounted() {
        this.getBooking()
        this.booking_store = new Booking(this.$store)
        this.booking_store.getBookingStatus()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_reference_number: 'itinerary/get_reference_number',
            get_selected_company: 'auth/get_selected_company',
            getBookingStatus: 'booking/getBookingStatusGetters'
        }),
        get_selected() {
            return this.selected.length === 0 ? true : false
        }
    },
    methods: {
        async getBooking(refresh = false) {
            if (refresh) {
                this.$toast.info('Refreshing...')
            }
            this.selected = []
            await this.$axios.get(`itinerary/booking/get_booking_with_status/${this.itinerary.id}`)
                .then(({ data }) => {
                    if (data.response) {
                        this.bookings = []
                        if (this.group_bookings) {
                            data.data.filter(booking => {
                                if (booking.get_child.length > 0) {
                                    booking.get_child.forEach(child => {
                                        this.bookings.push(child)
                                    })
                                }
                            })
                            const group = {}
                            this.bookings.forEach(obj => {
                                const id = obj.amenities.id
                                if (!group[id]) {
                                    group[id] = { ...obj, children: [] }
                                } else {
                                    group[id].children.push(obj)
                                }
                            })
                            this.bookings = Object.values(group)
                        }
                        else {
                            data.data.filter(booking => {
                                if (booking.get_child.length > 0) {
                                    booking.get_child.forEach(child => {
                                        this.bookings.push(child)
                                    })
                                }
                            })
                        }
                        this.loading = false
                        this.$toast.success('Refreshed bookings successfully')
                    }
                })
        },
        async download_to_pdf() {
            var options = {
                margin: 0.2,
                filename: this.itinerary.itinerary_name + "-voucher.pdf",
                image: { type: 'jpeg' },
                html2canvas: { scale: 1.2, useCORS: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
            }
            html2pdf().set(options).from(document.getElementById('element-to-convert-booking')).save()
            // html2pdf().from(element).set(options).toPdf().get('pdf').then(function (pdf) {
            //     for (let i = 1; i <= pdf.internal.getNumberOfPages(); i++) {
            //         pdf.setPage(i)
            //         pdf.addImage(img_data, "jpeg", 20, 10, 63, 23)
            //     }
            // }).save()
            this.timer = setInterval(() => {
                this.selected = []
                clearInterval(this.timer)
            }, 1500)
        },
        get_range(start, end) {
            const date1 = new Date(start)
            const date2 = new Date(end)
            const diff_in_time = date2.getTime() - date1.getTime()
            const diff_in_days = diff_in_time / (1000 * 3600 * 24)
            return diff_in_days
        },
        request_event(item) {
            switch (item) {
                case 'availability_request': {
                    this.availability_request_model = true
                    break
                }
                case 'booking_request': {
                    this.availability_request_model = true
                    this.title = "Send booking request"
                    break
                }
                case 'amendment_request': {
                    alert('amendment_request')
                    break
                }
                case 'cancellation_request': {
                    this.availability_request_model = true
                    this.title = "Cancel booking request"
                    break
                }
                case 'courtesy_reconfirmation': {
                    alert('courtesy_reconfirmation')
                    break
                }
            }
        },
        close_dialog() {
            this.availability_request_model = false
            this.getBooking()
        },
        update_booking_id(data) {
            this.to_add_bk_id = data
            this.bk_id_model = true
        },
        closeBookingDialog() {
            this.to_add_bk_id = {}
            this.bk_id_model = false
        },
        async save_bk_dialog() {
            let tp = {
                id: this.to_add_bk_id.id,
                booking_reference_number: this.i.booking_id
            }
            this.$store.dispatch('itinerary/update_booking_id', tp)
            this.getBooking()
            this.closeBookingDialog()
        },
        async send_booking_request() {
            this.selected[0].reference_number = this.get_reference_number
            this.$store.dispatch('itinerary/send_booking_request', this.selected)
        },
        async evtChangeBookingStatus(supplier_id, status) {
            await this.$axios.patch(`itinerary/booking/update_booking_status/${supplier_id}`, { status_id: status })
                .then(({ data }) => {
                    if (data.response) {
                        this.getBooking()
                    }
                })
        },
        evtGetBookingStatusColor(booking_status) {
            if (booking_status != null) {
                switch (booking_status.id) {
                    case 1:
                        return 'primary'
                    case 2:
                        return 'warning'
                    case 3:
                        return 'success'
                    case 4:
                        return 'error'
                    case 5:
                        return 'error'
                }
            } else {
                return 'primary'
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.card {
    max-width: 100%;
    background-color: white;
    margin: 25px 15px;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.card .card-header {
    font-size: 24px;
    color: #343642;
    margin-bottom: 15px;
}

.card .card-header-items {
    text-align: right;
}

.card .card-header-items .header-items {
    margin: 0px 10px;
}

.dialog-close {
    margin-left: -75px;
    position: absolute;
}

.custom-label {
    background-color: grey;
    border-radius: 10px;
    color: white;
    font-size: 9px;
    padding: 3px 5px;
}

:deep(div) {
    page-break-inside: avoid;
}
</style>