<template>
    <v-container fluid style="background-color: #F4F4F5 !important; height: 100%;" class="pa-0 ma-0">
        <v-app-bar app clipped-left dark color="#447fa6" elevation="0">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-card elevation="12" width="256">
            <v-navigation-drawer floating app :permanent="!$vuetify.breakpoint.mdAndDown" v-model="drawer"
                color="#588BAD" class="no-border shadow">
                <v-card-title style="color: white;">
                    PlanMyTour
                </v-card-title>
                <v-card-text style="color: white !important;" v-if="get_user.user_role_id === 1">
                    <v-list-item two-line style="cursor: pointer;" @click="openSelectCompanyDialog">
                        <v-avatar color="grey" size="30" class="mr-2">
                            {{ get_user.name[0] }}
                        </v-avatar>
                        <v-list-item-content>
                            <v-list-item-title style="font-size: 14px; color: white;">
                                {{ get_user.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px; color: white;">
                                {{ get_selected_company.company_name }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card-text>
                <v-card-text style="color: white !important;" v-else>
                    <v-list-item two-line>
                        <v-avatar color="grey" size="30" class="mr-2">
                            {{ get_user.name[0] }}
                        </v-avatar>
                        <v-list-item-content>
                            <v-list-item-title style="font-size: 14px; color: white;">
                                {{ get_user.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px; color: white;">
                                {{ get_selected_company.company_name }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card-text>
                <v-divider></v-divider>
                <v-list dense rounded v-if="get_selected_company.id">
                    <v-list-item v-for="(item, itemindex) in navigation" :key="itemindex" link
                        @click="$router.push(item.url)">
                        <v-list-item-icon>
                            <v-icon :style="item.style">{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title :style="item.style">{{ item.label }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <template v-slot:append>
                    <div>
                        <v-list dense rounded>
                            <v-list-item v-for="(item, itemindex) in navigation2" :key="itemindex" link
                                @click="item.url.name == '' ? logout() : $router.push(item.url)">
                                <v-list-item-icon>
                                    <v-icon :style="item.style">{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title :style="item.style">{{ item.label }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </template>
            </v-navigation-drawer>
        </v-card>
        <main class="main">
            <slot />
        </main>
        <v-row justify="center">
            <v-dialog max-width="620" v-model="select_company_dialog" persistent>
                <v-card>
                    <v-card-title style="font-size: 16px;">
                        <small>Choose Company</small>
                        <v-spacer></v-spacer>
                        <v-icon @click="closeSelectCompanyDialog">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-autocomplete v-model="company_selected" label="choose a company to work with today"
                            prepend-inner-icon="mdi-tooltip-text-outline" :items="get_companies" item-value="id"
                            item-text="company_name" outlined dense chips return-object>
                        </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block small dark color="#588BAD" rounded @click="selectCompany()">
                            Select
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    data: () => ({
        navigation: [
            {
                icon: 'mdi-home-outline',
                label: 'Home',
                style: 'color: white',
                url: {
                    name: '/dashboard'
                }
            },
            {
                icon: 'mdi-clipboard-list-outline',
                label: 'Itinerary',
                style: 'color: white',
                url: {
                    name: '/itinerary'
                }
            },
            {
                icon: 'mdi-clipboard-list-outline',
                label: 'Bookings',
                style: 'color: white',
                url: {
                    name: '/itinerary'
                }
            },
            {
                icon: 'mdi-account-box-multiple-outline',
                label: 'Suppliers',
                style: 'color: white',
                url: {
                    name: '/supplier'
                }
            },
            {
                icon: 'mdi-folder-multiple-outline',
                label: 'Resources',
                style: 'color: white',
                url: {
                    name: '/resources'
                }
            },
            {
                icon: 'mdi-account-group-outline',
                label: 'Contacts',
                style: 'color: white',
                url: {
                    name: '/contact'
                }
            },
            {
                icon: 'mdi-domain',
                label: 'Agencies',
                style: 'color: white',
                url: {
                    name: '/agencies'
                }
            },
            {
                icon: 'mdi-file-tree-outline',
                label: 'Tasks',
                style: 'color: white',
                url: {
                    name: '/task'
                }
            }
        ],
        navigation2: [
            {
                icon: 'mdi-cog-outline',
                label: 'Organisation',
                style: 'color: white',
                url: {
                    name: '/organisation'
                }
            },
            {
                icon: 'mdi-logout',
                label: 'Logout',
                style: 'color: white',
                url: {
                    name: ''
                }
            }
        ],
        select_company_dialog: false,
        company_selected: null,
        drawer: false
    }),
    computed: {
        ...mapGetters({
            get_user: 'auth/get_user',
            get_companies: 'auth/get_companies',
            get_selected_company: 'auth/get_selected_company'
        })
    },
    methods: {
        openSelectCompanyDialog() {
            this.select_company_dialog = true
        },
        closeSelectCompanyDialog() {
            this.select_company_dialog = false
        },
        selectCompany() {
            this.$store.dispatch('auth/set_selected_company', this.company_selected)
            this.closeSelectCompanyDialog()
        },
        async logout() {
            await this.$axios.post('/logout')
            this.$store.commit('auth/set_empty_data')
            this.$router.push({ name: '/' })
        }
    }
}
</script>
<style scoped>
.no-border {
    border-radius: 0px !important;
}

.shadow {
    box-shadow: 0 2px 10px 0px rgb(0 0 0 / 20%);
}
</style>