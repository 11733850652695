<template>
    <v-container fluid>
        <v-card elevation="0" class="mb-15">
            <v-skeleton-loader type="article" v-if="loading"></v-skeleton-loader>
            <v-card v-else>
                <v-card-title>
                    <v-row style="text-align:right;">
                        <v-col cols="12">
                            <!-- <v-btn rounded color="primary" class="pt-4 pb-4 ml-2"
                                style="text-transform: unset !important;" @click="printItinerary('preview-to-pdf')">
                                <v-icon class="mr-2">
                                    mdi-download
                                </v-icon>
                                Print Itinerary
                            </v-btn> -->
                            <!-- <v-btn rounded color="primary" class="pt-4 pb-4 ml-2"
                                style="text-transform: unset !important;" @click="downloadContactList()">
                                <v-icon class="mr-2">
                                    mdi-download
                                </v-icon>
                                Contact List
                            </v-btn> -->
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <div id="itinerary-preview">
                        <v-card elevation="0" class="mb-15">
                            <v-img eager class="mx-auto my-5"
                                :style="isMobile() ? 'object-fit:contain;text-align:center;' : 'object-fit:contain;width:25%;text-align:center;'"
                                :src="itinerary.company.logo" />
                            <div class="mb-5">
                                <h1 style="font-weight: 700; font-size:20px;text-align:center;color:#1DA057;">
                                    {{ itinerary.itinerary_name }}
                                </h1>
                                <v-spacer />
                                <h5 style="font-weight: 500; font-size: 14px;text-align:center;">
                                    {{ $date(itinerary.start_date).format('D MMM') }}
                                    -
                                    {{ $date(itinerary.end_date).format('D MMM YYYY') }}
                                </h5>
                            </div>
                            <v-row :style="isMobile() ? 'padding: 10px 0px;' : 'padding: 50px 100px 0px 100px;'"
                                v-if="show_pricing">
                                <v-col cols="7">
                                    <h3 class="mb-3">TOTAL PRICE FOR THIS PACKAGE:</h3>
                                </v-col>
                                <v-col cols="5">
                                    <h3 class="mb-3">
                                        {{ itinerary.currency }} {{ getGross() | formatNumber }}
                                    </h3>
                                </v-col>
                                <v-col cols="7">
                                    <h3 class="mb-3">PRICE PER PERSON:</h3>
                                </v-col>
                                <v-col cols="5">
                                    <h3 class="mb-3">
                                        {{ itinerary.currency }}
                                        {{ getGross() / itinerary.travellers_number | formatNumber }}
                                    </h3>
                                </v-col>
                                <v-col cols="7">
                                    <h3 class="mb-3">DEPOSIT:</h3>
                                </v-col>
                                <v-col cols="5">
                                    <h3 class="mb-3">
                                        {{ itinerary.currency }}
                                        {{ getGross() * itinerary.deposit_percent | formatNumber }}
                                    </h3>
                                </v-col>
                                <v-col cols="7">
                                    <h3>BALANCE DUE:</h3>
                                </v-col>
                                <v-col cols="5">
                                    <h3>
                                        {{ itinerary.currency }}
                                        {{ getGross() - (getGross() * itinerary.deposit_percent) | formatNumber }}
                                    </h3>
                                </v-col>
                            </v-row>
                        </v-card>
                        <br>
                        <div v-html="itinerary.comments" class="my-10 px-10"></div>
                        <br class="html2pdf__page-break" v-if="itinerary.comments">
                        <v-card elevation="0" class="mb-15" v-if="show_outline">
                            <div
                                style="text-align:center;font-size:26px;margin-top:20px;margin-bottom:20px;font-weight:600;color:#800000;">
                                ITINERARY SUMMARY
                            </div>
                            <hr>
                            <Outline :builds="grouped_builds_with_rates" :itinerary="itinerary"
                                :show_supplier_name="show_supplier_name" :show_product_name="show_product_name"
                                :printing="true" />
                        </v-card>
                        <br class="html2pdf__page-break">
                        <v-card elevation="0" class="mb-15" v-if="show_itinerary">
                            <div
                                style="text-align:center;font-size:26px;margin-top:20px;margin-bottom:20px;font-weight:600;color:#800000;">
                                ITINERARY DETAIL
                            </div>
                            <hr>
                            <Itinerary :builds="grouped_builds_with_rates" :itinerary="itinerary"
                                :show_supplier_name="show_supplier_name" :show_product_name="show_product_name" />
                        </v-card>
                    </div>
                    <!-- <div id="contact-list">
                        <v-card elevation="0">
                            <v-card-text style="text-align:center;">
                                <h1>Contact List</h1>
                                <br>
                                <h2>{{ itinerary.itinerary_name }}</h2>
                                <br>
                                <h3>Itinerary Ref: {{ itinerary.internal_reference }},
                                    From: {{ $date(itinerary.start_date).format('D MMM, YYYY') }},
                                    To: {{ $date(itinerary.end_date).format('D MMM, YYYY') }},
                                    {{
                                    $date(itinerary.end_date).diff($date(itinerary.start_date),
                                    'DAY') + 1
                                    }} days
                                </h3>
                                <br>
                                <div v-for="(type, contact_index) in contact_types" :key="contact_index">
                                    <h3 style="text-align:left;">{{ type }}</h3>
                                    <v-data-table :headers="headers" :items="builds_with_rates.filter((obj, index) => {
                                        return index === builds_with_rates.findIndex(o => obj.option.product.supplier.id === o.option.product.supplier.id)
                                            && obj.option.product.supplier.service_type_id == contact_index + 1
                                    })" hide-default-footer disable-pagination>
                                        <template v-slot:item="{ item }">
                                            <tr>
                                                <td><b>{{ $date(item.check_in_date).format('DD MMM YY') }}</b></td>
                                                <td><b>{{ $date(item.check_out_date).format('DD MMM YY') }}</b></td>
                                                <td style="text-align:left;"><b>{{
                                                        item.option.product.supplier.supplier_name }}</b></td>
                                                <td style="text-align:left;"><b>{{ item.option.product.supplier.address
                                                        }}</b></td>
                                                <td style="text-align:left;"><b>{{ item.option.product.supplier.phone
                                                        }}</b>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                    <br>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div> -->
                </v-card-text>
            </v-card>
        </v-card>
    </v-container>
</template>

<script>
import Outline from './preview/components/Outline.vue'
import Itinerary from './preview/components/itinerary.vue'
import html2pdf from 'html2pdf.js'
export default {
    components: {
        Outline,
        Itinerary,
    },
    props: [
    ],
    data() {
        return {
            itinerary: null,
            builds_with_rates: [],
            grouped_builds_with_rates: [],
            conversion: 1,
            get_itinerary_preview: [],
            get_pricing: [],
            loading: true,
            show_pricing: true,
            show_outline: true,
            show_itinerary: true,
            show_supplier_name: true,
            show_product_name: false,
            items: [
                'Outline'
            ],
            tab: null,
            eur_to_gbp: null,
            gbp_to_eur: null,
            headers: [
                { text: 'Date in', width: '13%' },
                { text: 'Date out', width: '13%' },
                { text: 'Supplier' },
                { text: 'Address' },
                { text: 'Phone' },
            ],
            builds: [],
            contact_types: ['Accommodation', 'Activity', 'Rental Car'],
        }
    },
    async mounted() {
        this.getData()
    },
    created() {
    },
    computed: {
    },
    methods: {
        async refreshData() {
            this.$emit('refreshData')
        },
        async getData() {
            if (this.$route.params.id != null) {
                let payload = {
                    id: this.$route.params.id
                }
                await this.$axios.get('v2/itineraries/decrypt_itinerary_encrypted_id', payload)
                    .then(({ data }) => {
                        if (data.response) {
                            this.itinerary = data.data
                            console.log(this.itinerary)
                            document.title = this.itinerary.company.company_name
                            this.getBuildsWithRates()
                        }
                    })
            }
        },
        async getBuildsWithRates() {
            await this.$axios.get(`v2/itineraries/get_builds_with_rates/${this.itinerary.id}`)
                .then(({ data }) => {
                    let sort_order = [1, 3, 2]
                    this.builds_with_rates = data.data
                    this.grouped_builds_with_rates = Object.values(Object.groupBy(data.data, ({ ib_id }) => ib_id))
                    this.grouped_builds_with_rates.forEach(day => {
                        day.sort((a, b) => (sort_order.indexOf(a.option.product.supplier.service_type_id) > sort_order.indexOf(b.option.product.supplier.service_type_id))
                            ? 1 : ((sort_order.indexOf(b.option.product.supplier.service_type_id) > sort_order.indexOf(a.option.product.supplier.service_type_id)) ? -1 : 0))
                    })

                    this.conversion = this.itinerary.currency == 'EUR' ? data.gbp_to_eur : data.eur_to_gbp
                    this.loading = false
                })
        },
        getGross() {
            let total = 0
            this.builds_with_rates.forEach(build => {
                let days = this.$date(build.check_out_date).diff(this.$date(build.check_in_date), 'd')
                if (this.itinerary.currency != build.option.product.supplier.currency) {
                    total += (build.rate_day ? build.rate_day.gross : 0) * (days > 0 ? days : 1) * build.count * this.conversion
                }
                else {
                    total += (build.rate_day ? build.rate_day.gross : 0) * (days > 0 ? days : 1) * build.count
                }
            })
            return Math.abs(total) + parseFloat(this.itinerary.service_charge) + parseFloat(this.itinerary.orr)
        },
        downloadContactList() {
            const opt = {
                margin: 0.1,
                filename: this.itinerary.itinerary_name + "-Contact List.pdf",
                image: { type: 'png', quality: 1 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            }
            html2pdf().set(opt).from(document.getElementById("contact-list")).save();
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(tr),
:deep(h3),
:deep(h2),
:deep(h1) {
    page-break-inside: avoid;
    color: black !important;
}

:deep(th) {
    color: black !important;
    font-weight: 800 !important;
}
</style>